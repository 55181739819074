<template>
    <div class="onlinePrintSet">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['save','goBack']"
                @saveClick="saveData()"
                @goBackClick="$router.back()"/>
        </div>
        <div class="content-box">
            <div class="left-nav">
                <div class="nav">
                    <div class="title verticalCenter">
                        <i class="iconfont icon-mendianshebeiliebiao"></i> 打印单类型
                    </div>
                    <div class="li" :class="{selected:navIndex==0}"  @click="navIndex=0" >
                        <i class="iconfont icon-shebei"></i>客看单
                        <i class="iconfont icon-gou-fine"></i>
                    </div>
                    <div class="li" :class="{selected:navIndex==1}"  @click="navIndex=1" >
                        <i class="iconfont icon-shebei"></i>结账单
                        <i class="iconfont icon-gou-fine"></i>
                    </div>
                    <div class="li" :class="{selected:navIndex==2}"  @click="navIndex=2" >
                        <i class="iconfont icon-shebei"></i>外卖结账单
                        <i class="iconfont icon-gou-fine"></i>
                    </div>
                    <div class="li" :class="{selected:navIndex==3}"  @click="navIndex=3" >
                        <i class="iconfont icon-shebei"></i>消费清单
                        <i class="iconfont icon-gou-fine"></i>
                    </div>
                    <div class="li" :class="{selected:navIndex==4}"  @click="navIndex=4" >
                        <i class="iconfont icon-shebei"></i>退菜单
                        <i class="iconfont icon-gou-fine"></i>
                    </div>
                </div>
            </div>
            <div class="right-frame-box">
                <div class="panel-box">
                    <div class="panel-heading">前厅打印出单——{{Department_Name}}</div>
                    <div class="panel-body">
                        <div class="group-box">
                            <div class="tr-li">
                                <el-switch v-if="navIndex==0" v-model="Is_ScanQrKKPrint" :inline-prompt="true" active-text="是否启用" inactive-text=""></el-switch>
                                <el-switch v-else-if="navIndex==1" v-model="Is_ScanQrJZPrint" :inline-prompt="true" active-text="是否启用" inactive-text=""></el-switch>
                                <el-switch v-else-if="navIndex==2" v-model="IS_TakeawayPrint" :inline-prompt="true" active-text="是否启用" inactive-text=""></el-switch>
                                <el-switch v-else-if="navIndex==3" v-model="IS_Open_PrintPrint" :inline-prompt="true" active-text="是否启用" inactive-text=""></el-switch>
                                <el-switch v-else-if="navIndex==4" v-model="IS_Open_PrintRefund" :inline-prompt="true" active-text="是否启用" inactive-text=""></el-switch>
                                <el-radio-group v-model="data.Print_Kind" @change="kindChange(data)">
                                    <el-radio :label="1">方式一：按所有区域统一打印{{printName}}</el-radio>
                                    <el-radio :label="2">方式二:按指定区域分配打印{{printName}}</el-radio>
                                </el-radio-group>
                            </div>
                            
                            <div class="tr-li table-box" v-if="data.Print_Kind==2">
                                <el-table class="el-table--scrollable-y" style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                                    :data="data.tableData"
                                    highlight-current-row
                                    ref="tableEl"
                                    >
                                    <el-table-column fixed type="index" label="序号" width="50" align="center"/>
                                    <el-table-column fixed label="操作" width="70" align="center">
                                        <template #default="scope">
                                            <div class="operation">
                                                <b class="iconfont icon-jia" @click="add(data.tableData,scope.$index)"></b>
                                                <b class="iconfont icon-jian" @click="del(data.tableData,scope.$index)"></b>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="打印模板" width="150" align="center">
                                        <template #default="scope">
                                            <el-select v-model="scope.row.Print_TemplateID" placeholder="请选择打印模板" @focus="$refs.tableEl?.setCurrentRow(scope.row)">
                                                <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in modelsData" :key="item"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="打印机" width="150" align="center">
                                        <template #default="scope">
                                            <el-select v-model="scope.row.Printer_ID" placeholder="请选择打印机"  @focus="$refs.tableEl?.setCurrentRow(scope.row)">
                                                <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="打印张数" width="100" align="center">
                                        <template #default="scope">
                                            <div class="from-input">
                                                <inputPattern v-model="scope.row.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"/>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="选择区域" min-width="150" align="center">
                                        <template #default="scope">
                                            <el-select v-model="scope.row.ROOM_IDs" multiple collapse-tags @change="roomChange(scope.row)"  @focus="$refs.tableEl?.setCurrentRow(scope.row)">
                                                <el-option :value="item.Room_AutoID" :label="item.Room_Code+'-'+ item.Room_Name" v-for="item in PosRooms" :key="item"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="排除桌号" min-width="150" align="center">
                                        <template #default="scope">
                                            <select-tree v-model="scope.row.DESK_IDs"  @click="$refs.tableEl?.setCurrentRow(scope.row)"
                                                title="选择桌号"
                                                :data="getRoomTree(PosRooms?.filter(t=>scope.row.ROOM_IDs?.indexOf(t.Room_AutoID)>=0)||[])"
                                                ></select-tree>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="指定打印任务来源" min-width="160" align="center">
                                        <template #default="scope">
                                            <select-tree v-model="scope.row.SourcePosEqIDs" @click="$refs.tableEl?.setCurrentRow(scope.row)"
                                                title="选择打印任务来源"
                                                placeholder="默认所有设备"
                                                :data="SourcePosEquipments"
                                                ></select-tree>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="订阅打印设备" min-width="160" align="center">
                                        <template #default="scope">
                                            <el-select v-model="scope.row.PosEqID"  @change="roomChange(scope.row)" placeholder="默认厨房打印服务" @focus="$refs.tableEl?.setCurrentRow(scope.row)">
                                                <el-option value="" label="默认厨房打印服务"></el-option>
                                                <el-option :value="item.Equipment_ID" :label="item.Equipment_Name" v-for="item in posEquipments" :key="item"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="tr-li" v-else>
                                <div class="td-3">
                                    <div class="lable-txt">打印模板：</div>
                                    <div class="from-input">
                                        <el-select v-model="data.Print_TemplateID" placeholder="请选择打印模板" :disabled="data.Print_Kind!=1">
                                            <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in modelsData" :key="item"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="td-3">
                                    <div class="lable-txt">选择打印机：</div>
                                    <div class="from-input">
                                        <el-select v-model="data.Printer_ID" placeholder="请选择打印机" :disabled="data.Print_Kind!=1">
                                            <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="td-3">
                                    <div class="lable-txt">打印份数：</div>
                                    <div class="from-input"><inputPattern v-model="data.Print_Num" type="number" pattern="number" :min="1" :dplaces="0" :disabled="data.Print_Kind!=1"></inputPattern></div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//线上打印机分配
export default {
    name:"onlinePrintSet",
    data(){
        return {
            navIndex:0,
            Department_Name:'',
            Is_ScanQrKKPrint:false,
            //扫码买单客看单
            ScanQrOrder_CstmView:{
                Print_Kind:0,
                Print_Num:1,
                tableData:[]
            },
            Is_ScanQrJZPrint:false,
            //扫码买单结账单
            ScanQrOrder_CheckOut:{
                Print_Kind:0,
                Print_Num:1,
                tableData:[]
            },
            IS_TakeawayPrint:false,
            //外卖结账单
            Takeaway_CheckOut:{
                Print_Kind:0,
                Print_Num:1,
                tableData:[]
            },
            IS_Open_PrintPrint:false,
            //消费清单
            Open_PrintView:{
                Print_Kind:0,
                Print_Num:1,
                tableData:[]
            },
            IS_Open_PrintRefund:false,
            //退菜单
            Open_PrintRefund:{
                Print_Kind:0,
                Print_Num:1,
                tableData:[]
            },
            printers:[],
            printModels:[],
            posEquipments:[],
            //桌台数据
            PosRooms:[]
        };
    },
    computed:{
        printName(){
            return (["客看单","结账单","外卖结账单","消费清单","退菜单"])[this.navIndex];
        },
        //客看单 模板 
        KK_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==8);
        },
        //结账单 模板
        JZ_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==2);
        },
        //外卖结账单 模板
        WM_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==6);
        },
        //消费清单 模板
        XF_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==1);
        },
        //退菜单 模板
        TC_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==820);
        },
        //模板
        modelsData(){
            if(this.navIndex==1){
                return this.JZ_printModels;//结账单
            }else if(this.navIndex==2){
                return this.WM_printModels;//外卖结账单
            }else if(this.navIndex==3){
                return this.XF_printModels;//消费清单
            }else if(this.navIndex==4){
                return this.TC_printModels;//退菜单 模板
            }
            return this.KK_printModels;//客看单 
        },
        //数据
        data(){
            if(this.navIndex==1){
                return this.ScanQrOrder_CheckOut;//结账单
            }else if(this.navIndex==2){
                return this.Takeaway_CheckOut;//外卖结账单
            }else if(this.navIndex==3){
                return this.Open_PrintView;//消费清单 模板
            }else if(this.navIndex==4){
                return this.Open_PrintRefund;//退菜单 模板
            }
            return this.ScanQrOrder_CstmView;//客看单
        },
        SourcePosEquipments(){
            let arr=[{id:"SourceFromOnlineQrOrder",name:"扫码点单"},{id:"SourceFromTakeOutPlatform",name:"外卖平台"}];
            this.posEquipments.forEach((it)=>{
                arr.push({id:it.Equipment_ID,name:it.Equipment_Name})
            })
            return arr;
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        let userInfo= this.$auth.getUserInfo();
        if(userInfo){
            this.Department_Name=userInfo.Department_Name;
        }
        this.$cacheData.EatRooms().then((d)=>{
            this.PosRooms=d;
        }).catch((e)=>{
            this.$alert("桌台数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
        });
        this.loadBaseData();
        this.loadData();
    },
    methods:{
        loadBaseData(){
            this.$cacheData.Printers().then((d)=>{
                this.printers=d;
            }).catch(e=>{
                console.log('打印机数据获取失败e:'+e)
            })
            this.$cacheData.PrintModels().then(d=>{
                this.printModels=d
            }).catch(e=>{
                console.log('打印模板数据获取失败e:'+e)
            });
            //获取设备信息
            this.$cacheData.PosEquipments().then(d=>{
                this.posEquipments=d.filter(t=>t.Sys_ID==801 || t.Sys_ID==802);
            }).catch(e=>{
                console.log('设备信息数据获取失败e:'+e)
            });
        },
        //线上打印机分配数据
        loadData(){
            const loading = this.$loading({
                text: "加载线上打印机分配数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPosTakeOutPrintSetInfo",{ }).then((d)=>{
                loading.close();
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    let info=d.ResponseBody||{};
                    if(info){
                        this.Assigned_ID=info.Assigned_ID;
                        if(info.Open_ScanQrOrder_CstmView_Detail && info.Open_ScanQrOrder_CstmView_Detail.length>0){
                            this.Is_ScanQrKKPrint=true;
                            if(info.Open_ScanQrOrder_CstmView_Detail[0].Print_Kind==1){
                                this.ScanQrOrder_CstmView=info.Open_ScanQrOrder_CstmView_Detail[0];
                            }else{
                                this.ScanQrOrder_CstmView={
                                    Print_Kind:2,
                                    tableData:info.Open_ScanQrOrder_CstmView_Detail
                                };
                            }
                        }
                        if(info.Open_ScanQrOrder_CheckOut_Detail && info.Open_ScanQrOrder_CheckOut_Detail.length>0){
                            this.Is_ScanQrJZPrint=true;
                            if(info.Open_ScanQrOrder_CheckOut_Detail[0].Print_Kind==1){
                                this.ScanQrOrder_CheckOut=info.Open_ScanQrOrder_CheckOut_Detail[0];
                            }else{
                                this.ScanQrOrder_CheckOut={
                                    Print_Kind:2,
                                    tableData:info.Open_ScanQrOrder_CheckOut_Detail
                                };
                            }
                        }
                        if(info.Open_Takeaway_CheckOut_Detail && info.Open_Takeaway_CheckOut_Detail.length>0){
                            this.IS_TakeawayPrint=true;
                            if(info.Open_Takeaway_CheckOut_Detail[0].Print_Kind==1){
                                this.Takeaway_CheckOut=info.Open_Takeaway_CheckOut_Detail[0];
                            }else{
                                this.Takeaway_CheckOut={
                                    Print_Kind:2,
                                    tableData:info.Open_Takeaway_CheckOut_Detail
                                };
                            }
                        }
                        if(info.Open_PrintView_Detail && info.Open_PrintView_Detail.length>0){
                            this.IS_Open_PrintPrint=true;
                            if(info.Open_PrintView_Detail[0].Print_Kind==1){
                                this.Open_PrintView=info.Open_PrintView_Detail[0];
                            }else{
                                this.Open_PrintView={
                                    Print_Kind:2,
                                    tableData:info.Open_PrintView_Detail
                                };
                            }
                        }

                        if(info.Open_PrintRefund_Detail && info.Open_PrintRefund_Detail.length>0){
                            this.IS_Open_PrintRefund=true;
                            if(info.Open_PrintRefund_Detail[0].Print_Kind==1){
                                this.Open_PrintRefund=info.Open_PrintRefund_Detail[0];
                            }else{
                                this.Open_PrintRefund={
                                    Print_Kind:2,
                                    tableData:info.Open_PrintRefund_Detail
                                };
                            }
                        }
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('未找到线上打印机分配数据：'+e);
                console.log('未找到线上打印机分配数据：'+e);
            })
        },
        add(table,index){
            if(!table) return;
            table.splice(index+1,0,{Print_Num:1});
        },
        del(table,index){
            if(!table) return;
            table.splice(index,1);
            if(table.length==0){
                this.add(table,0)
            }
        },
        roomChange(row){
            row.Desk_AutoIDs=[];
        },
        kindChange(data){
            if(data.Print_Kind==2 && (!data.tableData || data.tableData.length==0)){
                data.tableData=[{Print_Num:1}];
            }
        },
        /**保存数据 */
        saveData(){
            let info={
                Assigned_ID:this.Assigned_ID
            }
            //扫码买单客看单
            if(this.Is_ScanQrKKPrint && (this.ScanQrOrder_CstmView.Print_Kind==1 || this.ScanQrOrder_CstmView.Print_Kind==2)){
                if(this.ScanQrOrder_CstmView.Print_Kind==1){
                    info.Open_ScanQrOrder_CstmView_Detail=[{
                        Print_Kind:1,
                        Print_TemplateID:this.ScanQrOrder_CstmView.Print_TemplateID,
                        Printer_ID:this.ScanQrOrder_CstmView.Printer_ID,
                        Print_Num:this.ScanQrOrder_CstmView.Print_Num
                    }];
                }else{
                    info.Open_ScanQrOrder_CstmView_Detail=this.ScanQrOrder_CstmView.tableData?.map(t=>{
                        return {
                            Print_Kind:2,
                            Print_TemplateID:t.Print_TemplateID,
                            Printer_ID:t.Printer_ID,
                            Print_Num:t.Print_Num,
                            ROOM_IDs:t.ROOM_IDs,
                            DESK_IDs:t.DESK_IDs,
                            SourcePosEqIDs:t.SourcePosEqIDs,
                            PosEqID:t.PosEqID
                        }
                    })
                }
            }
            //扫码买单结账单
            if(this.Is_ScanQrJZPrint && (this.ScanQrOrder_CheckOut.Print_Kind==1 || this.ScanQrOrder_CheckOut.Print_Kind==2)){
                if(this.ScanQrOrder_CheckOut.Print_Kind==1){
                    info.Open_ScanQrOrder_CheckOut_Detail=[{
                        Print_Kind:1,
                        Print_TemplateID:this.ScanQrOrder_CheckOut.Print_TemplateID,
                        Printer_ID:this.ScanQrOrder_CheckOut.Printer_ID,
                        Print_Num:this.ScanQrOrder_CheckOut.Print_Num
                    }];
                }else{
                    info.Open_ScanQrOrder_CheckOut_Detail=this.ScanQrOrder_CheckOut.tableData?.map(t=>{
                        return {
                            Print_Kind:2,
                            Print_TemplateID:t.Print_TemplateID,
                            Printer_ID:t.Printer_ID,
                            Print_Num:t.Print_Num,
                            ROOM_IDs:t.ROOM_IDs,
                            DESK_IDs:t.DESK_IDs,
                            SourcePosEqIDs:t.SourcePosEqIDs,
                            PosEqID:t.PosEqID
                        }
                    })
                }
            }
            //外卖结账单
            if(this.IS_TakeawayPrint && (this.Takeaway_CheckOut.Print_Kind==1 || this.Takeaway_CheckOut.Print_Kind==2)){
                if(this.Takeaway_CheckOut.Print_Kind==1){
                    info.Open_Takeaway_CheckOut_Detail=[{
                        Print_Kind:1,
                        Print_TemplateID:this.Takeaway_CheckOut.Print_TemplateID,
                        Printer_ID:this.Takeaway_CheckOut.Printer_ID,
                        Print_Num:this.Takeaway_CheckOut.Print_Num
                    }];
                }else{
                    info.Open_Takeaway_CheckOut_Detail=this.Takeaway_CheckOut.tableData?.map(t=>{
                        return {
                            Print_Kind:2,
                            Print_TemplateID:t.Print_TemplateID,
                            Printer_ID:t.Printer_ID,
                            Print_Num:t.Print_Num,
                            ROOM_IDs:t.ROOM_IDs,
                            DESK_IDs:t.DESK_IDs,
                            SourcePosEqIDs:t.SourcePosEqIDs,
                            PosEqID:t.PosEqID
                        }
                    })
                }
            }

            //消费清单
            if(this.IS_Open_PrintPrint && (this.Open_PrintView.Print_Kind==1 || this.Open_PrintView.Print_Kind==2)){
                if(this.Open_PrintView.Print_Kind==1){
                    info.Open_PrintView_Detail=[{
                        Print_Kind:1,
                        Print_TemplateID:this.Open_PrintView.Print_TemplateID,
                        Printer_ID:this.Open_PrintView.Printer_ID,
                        Print_Num:this.Open_PrintView.Print_Num
                    }];
                }else{
                    info.Open_PrintView_Detail=this.Open_PrintView.tableData?.map(t=>{
                        return {
                            Print_Kind:2,
                            Print_TemplateID:t.Print_TemplateID,
                            Printer_ID:t.Printer_ID,
                            Print_Num:t.Print_Num,
                            ROOM_IDs:t.ROOM_IDs,
                            DESK_IDs:t.DESK_IDs,
                            SourcePosEqIDs:t.SourcePosEqIDs,
                            PosEqID:t.PosEqID
                        }
                    })
                }
            }

            //退菜单
            if(this.IS_Open_PrintRefund && (this.Open_PrintRefund.Print_Kind==1 || this.Open_PrintRefund.Print_Kind==2)){
                if(this.Open_PrintRefund.Print_Kind==1){
                    info.Open_PrintRefund_Detail=[{
                        Print_Kind:1,
                        Print_TemplateID:this.Open_PrintRefund.Print_TemplateID,
                        Printer_ID:this.Open_PrintRefund.Printer_ID,
                        Print_Num:this.Open_PrintRefund.Print_Num
                    }];
                }else{
                    info.Open_PrintRefund_Detail=this.Open_PrintRefund.tableData?.map(t=>{
                        return {
                            Print_Kind:2,
                            Print_TemplateID:t.Print_TemplateID,
                            Printer_ID:t.Printer_ID,
                            Print_Num:t.Print_Num,
                            ROOM_IDs:t.ROOM_IDs,
                            DESK_IDs:t.DESK_IDs,
                            SourcePosEqIDs:t.SourcePosEqIDs,
                            PosEqID:t.PosEqID
                        }
                    })
                }
            }
            //检测数据格式是否 正确
            try {
                info.Open_ScanQrOrder_CstmView_Detail?.forEach(d => {
                    if(!d.Print_TemplateID){
                        throw '客看单打印模板未设置！'
                    }
                    if(!d.Printer_ID){
                        throw '客看单打印机未设置！'
                    }
                    if(!(d.Print_Num>0)){
                        throw '客看单打印份数必须大于0！'
                    }
                    if(d.Print_Kind==2){
                        if(!d.ROOM_IDs || d.ROOM_IDs.length==0){
                            throw '客看单打印区域未选择！'
                        }
                    }
                });
                //结账单
                info.Open_ScanQrOrder_CheckOut_Detail?.forEach(d => {
                    if(!d.Print_TemplateID){
                        throw '结账单打印模板未设置！'
                    }
                    if(!d.Printer_ID){
                        throw '结账单打印机未设置！'
                    }
                    if(!(d.Print_Num>0)){
                        throw '结账单打印份数必须大于0！'
                    }
                    if(d.Print_Kind==2){
                        if(!d.ROOM_IDs || d.ROOM_IDs.length==0){
                            throw '结账单打印区域未选择！'
                        }
                    }
                });
                //外卖结账单
                info.Open_ScanQrOrder_CstmView_Detail?.forEach(d => {
                    if(!d.Print_TemplateID){
                        throw '外卖结账单打印模板未设置！'
                    }
                    if(!d.Printer_ID){
                        throw '外卖结账单打印机未设置！'
                    }
                    if(!(d.Print_Num>0)){
                        throw '外卖结账单打印份数必须大于0！'
                    }
                    if(d.Print_Kind==2){
                        if(!d.ROOM_IDs || d.ROOM_IDs.length==0){
                            throw '外卖结账单打印区域未选择！'
                        }
                    }
                });
                //消费清单
                info.Open_PrintView_Detail?.forEach(d => {
                    if(!d.Print_TemplateID){
                        throw '消费清单打印模板未设置！'
                    }
                    if(!d.Printer_ID){
                        throw '消费清单打印机未设置！'
                    }
                    if(!(d.Print_Num>0)){
                        throw '消费清单打印份数必须大于0！'
                    }
                    if(d.Print_Kind==2){
                        if(!d.ROOM_IDs || d.ROOM_IDs.length==0){
                            throw '消费清单打印区域未选择！'
                        }
                    }
                });

                //退菜单
                info.Open_PrintRefund_Detail?.forEach(d => {
                    if(!d.Print_TemplateID){
                        throw '退菜单打印模板未设置！'
                    }
                    if(!d.Printer_ID){
                        throw '退菜单打印机未设置！'
                    }
                    if(!(d.Print_Num>0)){
                        throw '退菜单打印份数必须大于0！'
                    }
                    if(d.Print_Kind==2){
                        if(!d.ROOM_IDs || d.ROOM_IDs.length==0){
                            throw '退菜单打印区域未选择！'
                        }
                    }
                });
            } catch (error) {
                this.$message.warning(error);
                return;
            }
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.SavePosTakeOutPrintInfo",{
                PosTakeOutPrintSetInfo:info,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('站点打印机分配数据保存失败：'+e);
                console.log('站点打印机分配数据保存失败：'+e);
            })
        },
        /**返回桌号数据 */
        getRoomTree(data){
            let treeData=[];
            data?.map(group=>{
                let json={
                    id:group.Room_AutoID,
                    name:group.Room_Code+group.Room_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.PosDesks){
                    group.PosDesks.map(item=>{
                        let children= Object.assign({
                            id:item.Desk_AutoID,
                            name:item.Desk_Code+'-'+item.Desk_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        }
    }
}
</script>

<style lang="scss">
@import './onlinePrintSet.scss'
</style>